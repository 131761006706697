import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="mail-recipient"
export default class extends Controller {
  connect() {
    let root = this.data.get('target-id');
    if (root == undefined) return;

    ['name', 'email', 'skoda-download-bool', 'vwaudi-download-bool', 'jobs-errors-bool'].forEach(field => {
      document.querySelector(`tr#${root} td[name='${field}'`).innerHTML = this.data.get(field);
    })
  }
}
