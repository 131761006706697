import { Controller } from "@hotwired/stimulus"
import consumer from "../channels/consumer"

// Connects to data-controller="message-list"
export default class extends Controller {
  static targets = ['messages'];

  connect() {
    this.channel = consumer.subscriptions.create('MessageChannel', {
      connected: this._cableConnected.bind(this),
      disconnected: this._cableDisconnected.bind(this),
      received: this._cableReceived.bind(this),
    });
  }

  _cableConnected() {
    // Called when the subscription is ready for use on the server
  }

  _cableDisconnected() {
    // Called when the subscription has been terminated by the server
  }

  _cableReceived(data) {
    // Called when there's incoming data on the websocket for this channel
    let doc = document.getElementById('messages-container')
    doc.hidden = null
    if (this.messagesTarget.innerHTML.toString() != "") this.messagesTarget.innerHTML += '<br>'
    this.messagesTarget.innerHTML += data.message;
  }
}
