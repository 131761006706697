import { Controller } from "@hotwired/stimulus"
// import { Toast } from "bootstrap"

// Connects to data-controller="css-dealer"
export default class extends Controller {
  connect() {
    let root = this.data.get('target-id');
    if (root == undefined) return;

    ['code', 'name', 'street', 'zip', 'city', 'created-at', 'updated-at'].forEach(field => {
      document.querySelector(`tr#${root} td[name='${field}'`).innerHTML = this.data.get(field);
    })
  }

  show() {

  }
}
