import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="css-application"
export default class extends Controller {
  connect() {
  }

  close(event) {
    event.preventDefault()
    event.stopPropagation()
    this.element.innerHTML = null
    return false
  }

  replace(response) {
    console.log(response.responseHTML)
    // this.element.innerHTML = response.responseHTML
  }
}
