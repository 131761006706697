import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="css-skoda-reports"
export default class extends Controller {
  connect() {
  }
  toggle_view() {
    let one = document.getElementById('block-one')
    let selected = document.getElementById('skoda_report_report_name')
    let value = selected.options[selected.selectedIndex].value
    if (value == 'cadas_type_failed_validation_report') {
      one.classList.remove("collapse")
    }
  }
}
