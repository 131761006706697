import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="css-import"
export default class extends Controller {
  connect() {
  }

  close(event) {
    event.preventDefault()
    event.stopPropagation()
    this.element.innerHTML = null
    return false
  }
}
