import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="css-password"
export default class extends Controller {
  connect() {
    let root = this.data.get('target-id');
    if (root == undefined) return;

    ['password', 'type-name', 'start-at', 'email', 'created-at'].forEach(field => {
      document.querySelector(`tr#${root} td[name='${field}'`).innerHTML = this.data.get(field);
    })
  }
}
