import {
  Controller
} from "@hotwired/stimulus"

// Connects to data-controller="settings"
export default class extends Controller {
  connect() {}
  add({
    params
  }) {
    let obj = document.getElementById(params.target)
    obj.innerHTML = params.content
  }
}