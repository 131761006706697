// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
// Turbo.session.drive = false
import "./controllers"
import * as bootstrap from "bootstrap"
import * as fontawsome from "fontawesome-free/js/all"

let popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))  
let popoverList = popoverTriggerList.map(function (popoverTriggerEl) {  
  return new bootstrap.Popover(popoverTriggerEl)  
})
import "./channels"
window.addEventListener('resize', (event) => {
  document.cookie = `inner_width=${window.innerWidth}`;
  document.cookie = `inner_height=${window.innerHeight}`;
});
document.cookie = `inner_width=${window.innerWidth}`;
document.cookie = `inner_height=${window.innerHeight}`;
