import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="css-realisation"
export default class extends Controller {
  connect() {
  }
  toggle_view() {
    let one = document.getElementById('block-one')
    let que = document.getElementById('block-events')
    let selected = document.getElementById('report_report_name')
    let value = selected.options[selected.selectedIndex].value
    if (value == 'quest_report') {
      one.classList.add("collapse")
      que.classList.remove("collapse")
    }  else if (value == 'dealers_list') {
      one.classList.add("collapse")
      que.classList.add("collapse")
    } else {
      one.classList.remove("collapse")
      que.classList.add("collapse")
    }
  }
}
